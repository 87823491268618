<template>
    <div class="redirectContainer">
        <div class="topBar">
            <div class="section-title sectionTitle">
                <h5>Recommendation</h5>
            </div>
            <div class="backButton" @click="onClickStartOver">
                <i class="fa-solid fa-angles-left"></i> Start Over
            </div>
        </div>
        <div class="redirectText" v-html="local_text"></div>
    </div>
</template>

<script>
export default ({
    name: 'LoaRedirectPage',
    props: {
        text: String,
        onClickStartOver : {
            type: Function
        },
        replacementStrings: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            local_text: null
        }
    },
    mounted() {
        this.$parent.piniaSurvey.setAttributes(null);
        this.local_text = this.text;
        if(this.replacementStrings != null) {
            const replacementKeys = this.replacementStrings ? Object.keys(this.replacementStrings) : null;
            let vm = this;
            replacementKeys.forEach((replacementKey) => {
                vm.local_text = vm.local_text.replaceAll(replacementKey, vm.replacementStrings[replacementKey]);
            });
        }
    }
})
</script>