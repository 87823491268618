const getTextForLanguage = function(jsonField, language) {
    if(!jsonField) return '';
    var field = jsonField.find((search) => search.language === language);
    if(field == null)  field = jsonField.find((search) => search.language === "English");
    return field != null && field.text != null ? field.text : '';
}

const setTextForLanguage = function(jsonField, language, newValue) {
    if(!jsonField) return;
    var field = jsonField.find((search) => search.language === language);
    if(field) field.text = newValue;
}

export const translation = {
    getTextForLanguage,
    setTextForLanguage
}