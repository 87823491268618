<template>
    <div class="flex_across">
        <div class="homePage">
            <div class="header noprint">
                <div class="logo">
                    <img src="/images/MTAB/mtab-logo.png" />
                </div>
                <div class="verticalDivider"></div>
                <div class="headerTitle">
                    {{memberLoungeTitle}}
                </div>
                <div class="translate">
                    <p> {{translationText }}</p>
                    <div>
                        <select v-model="selectedLanguage" @change="onLanguageSelect">
                            <option v-for="lang in languages" :key="lang.code" :value="lang.name">{{ lang.displayName }}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="content">
                <FrontPage v-if="page === 0" :onClickStart="getStarted" :clientCode="clientCode" :language="languageObject"></FrontPage>
                <Survey ref="survey" v-if="page===1" :code="surveyCode" sectionTitle=""
                    :onEndOfSurvey="local_onEndOfSurvey"
                    :onGoBack="previousPage" :goBackButtonText="goBackButtonText"
                    :language="languageObject" 
                    :definedBreadCrumbs="definedBreadCrumbs" :onBreadcrumbClick="breadcrumbClick">
                </Survey>
                <InformationPage v-if="page === 2 && activeInfoPageConfig" 
                    :language="languageObject"
                    :corporateLogoLocation="emailCorporateLogoPath" :key="componentKey"
                    :config="activeInfoPageConfig" :onClickStartOver="StartOver" :emailTemplate="emailTemplate" :replacements="replacements" 
                    :onBreadcrumbClick="breadcrumbClick"></InformationPage>
            </div>
            <div class="footer_container noprint" >
                <div class="footer">
                    <div>© 2002-{{year}}</div><div class="desktop_only"> | </div><div>The Jellyvision Lab, Inc.</div><div class="desktop_only"> | </div><div>All rights reserved.</div><div class="desktop_only"> | </div><div><a href="https://www.jellyvision.com/privacy-policy/" target="_blank">Privacy Policy</a></div>
                </div>
            </div>
            <FeedbackModal :questions="feedbackQuestions" thankYouMessage="Thanks for helping!"   ></FeedbackModal>
        </div>
    </div>
    </template>
    <script>
    import FrontPage from "@/components/MTAB/FrontPage";
    import Survey from "@/components/shared/Survey/Survey3";
    import InformationPage from '@/components/MTAB/InformationPage';
    import { useLoaStore } from "@/stores/loa";
    import FeedbackModal from "@/components/shared/FeedbackModal";
    import { data } from '@/shared';
    require('@/assets/css/client/MTAB/mtab_variables.scss');
    require('@/assets/css/shared/feedback/feedback_variables_standard.scss');
    require('@/assets/css/shared/send_email/send_email_variables_standard.scss');
    require('../../assets/css/shared/breadcrumb/breadcrumb_variables_standard.scss');
    
    export default {
      components: { FrontPage, Survey, InformationPage, FeedbackModal },
      name: "MTABBenefitsLounge",
      computed: {
            year() { 
                return new Date().getFullYear();
            },
            languageObject() {
                if(this.selectedLanguage != null && this.languages != null)
                    return this.languages.find(search => search.name === this.selectedLanguage);
                else 
                    return {}; 
            }
      },
      data() {
        return {
            page: 0,
            showModal: false,
            componentKey: 0,
            goBackButtonText: "Go Back",
            activeInfoPageConfig: {
                sendEmailOpenModalText: 'SEND ME THIS LIST',
                accordionCode: ''
            },
            emailTemplate: "mtabbenefitsloungeresultsemail",
            surveyCode: "MTAB_LOUNG",
            clientCode: "MTAB",
            activeRedirectText: null,
            replacements: null,
            webPageLogoPath: 'images/Demo/placeHolder.png',
            emailCorporateLogoPath: '/Demo/placeHolder.png',
            frontPageMainText: null,
            translationText: 'Translate this page to:',
            breadcrumbPages:[{
                number: 1,
                title: 'Home'
            }],
            definedBreadCrumbs: [{
                number: 1,
                titleJson: [
                {
                    language: "English",
                    text: "Home"
                },
                {
                    language: "Spanish",
                    text: "Hogar"
                },
                {
                    language: "Portuguese",
                    text: "Lar"
                }]
            }],
            memberLoungeTitle: 'Member Lounge',
            selectedLanguage: '',
            languages: [
                {
                    code: 'en',
                    displayName: 'English',
                    name: 'English'
                },
                {
                    code: 'es',
                    displayName: 'Español',
                    name: 'Spanish'
                },
                {
                    code: 'pt',
                    displayName: 'Português',
                    name: 'Portuguese'
                }
            ],
            feedbackQuestions: [
                {   question:"How helpful has this experience been?",
                    subject: "HOW_HELPFUL",
                    sequence: 1,
                    icons:[
                        {score: 1, class: 'far fa-grimace', text:'Not at all helpful'},
                        {score: 2, class: 'far fa-frown', text: 'Not very helpful'},
                        {score: 3, class: 'far fa-meh', text: "Somewhat helpful"},
                        {score: 4, class: 'far fa-smile', text: 'Very helpful'},
                        {score: 5, class: 'far fa-grin-stars', text: 'Extremely helpful'}
                    ]
                },
                {  question:"Do you feel like you have a better understanding of how the benefits available to you now?",
                    sequence: 2,
                    subject: "BETTER_UNDRSTNDG",
                    icons:[
                        {score: 1, class: 'far fa-grimace', text:'No, definitely not'},
                        {score: 2, class: 'far fa-frown', text: 'No, not really'},
                        {score: 3, class: 'far fa-meh', text: "Maybe"},
                        {score: 4, class: 'far fa-smile', text: 'Yes, a little'},
                        {score: 5, class: 'far fa-grin-stars', text: 'Yes, definitely'}
                    ]
                }
            ]
        };
      },   
      setup() {
        const piniaSurvey = useLoaStore();
        return {
            piniaSurvey,
        };
      },
      mounted() {    
            document.body.classList.add('loa_body');
    
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'LANDING_PAGE'
        });
        this.selectedLanguage = 'English';
        this.getClientConstants();
      },
      unmounted() {
          document.body.classList.remove('loa_body');
      },
      methods: {
        local_onEndOfSurvey: function(action) {        
            this.replacements = null; 
    
            if(action.configuration)
                this.activeInfoPageConfig = action.configuration;            
                
            this.page++;
        },
        ShowContactModal: async function() {
            data.postActivityLog({
                clientCode: this.clientCode,
                logActivityTypeCode: 'CLICK_LEAVE_MGMT'
            });
            this.showModal = true;
        },
        closeModal: function() {
            this.showModal = false;
        },
        getStarted: function() {
            data.postActivityLog({
                clientCode: this.clientCode,
                logActivityTypeCode: 'CLICK_GET_STARTED'
            });
            this.nextPage();
        },
        goToSurvey: function() {
            data.postActivityLog({
                clientCode: this.clientCode,
                logActivityTypeCode: 'CLICK_ELIGIBILITY'
            });
            this.nextPage();
        },
        nextPage: function() {
            this.page++;
        },
        previousPage: function() {
            this.page--;
        },
        StartOver: function() {
            this.page = 0;
            this.activeInfoPageConfig = null;
            this.activeRedirectText = null;
            this.piniaSurvey.setAttributes(null);
        },
        switchLanguage: function(lang) {
            this.language = lang;
            data.postActivityLog({
                clientCode: this.clientCode,
                logActivityTypeCode: 'CHANGE_LANGUAGE',
                data: JSON.stringify({ language: this.language })
            });
        },
        onLanguageSelect: function() { 
            data.postActivityLog({
                clientCode: this.clientCode,
                logActivityTypeCode: 'CHANGE_LANGUAGE',
                data: JSON.stringify({ language: this.selectedLanguage })
            });

            switch (this.selectedLanguage) {
                case 'English':
                    this.memberLoungeTitle = "Member Lounge";
                    this.goBackButtonText = "Go Back";
                    this.translationText = "Translate this page to:";
                    break;
                case 'Spanish':
                    this.memberLoungeTitle = "Salón para Miembros";
                    this.goBackButtonText = "Regresa";
                    this.translationText = "Traducir esta página a:";
                      break;
                case 'Portuguese':
                    this.memberLoungeTitle = "Salão do Membro";
                    this.goBackButtonText = "Volte";
                    this.translationText = "Traduzir esta página para:";
                    break;
            }
        },
        breadcrumbClick: function(breadcrumbPage) {
            if(breadcrumbPage.number === 1) {
                this.page = 0;
            } else if(this.page === 2 && breadcrumbPage.number === 2) {
                this.page = 1;
            }
        },
        getClientConstants: async function() {
            const constants = await data.getClientConstants();
            var attributes = this.piniaSurvey.getAttributes;
            if(constants != null) {
                constants.forEach(constant => {
                    attributes[constant.key] = constant.value;
                });
            }

            this.piniaSurvey.setAttributes(attributes);
        }
      },
    };
    </script>
    <style lang="scss" scoped src="@/assets/css/client/MTAB/mtab_member_lounge.scss"></style>
    <style lang="scss" scoped src="@/assets/css/client/MTAB/feedback_modal.scss"></style>
    <style lang="scss" scoped src="@/assets/css/client/MTAB/send_email_modal.scss"></style>
    <style lang="scss" scoped src="@/assets/css/shared/breadcrumb/breadcrumb_standard.scss"></style>
    <style scoped>
        @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
    </style>    