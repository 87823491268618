<template>
    <div class="topBar">
        <div class="navigationContainer">
            <Breadcrumb :pages="breadcrumbPages"
                :activePageNumber="3"
                separator=">" :language="language"
                :showFuturePages="false"
                :onBreadcrumbClick="breadcrumbPageClick">
            </Breadcrumb>
            <div class="breadcrumb noprint ">
                <div class="pastPage pointer"  @click="onClickStartOver">
                    {{ this.startOverText[language.name.toLowerCase()] }}
                </div>
                <div style="padding: 0px 10px;">|</div>
                <div class="pastPage pointer" @click="print()" >
                    {{ this.printText[language.name.toLowerCase()] }}
                </div>
            </div>
        </div>
    </div>
    <div class="informationPage">
        <div class="contentContainer">
            <div class="accordionHeader">
                <div class="textContainer">
                    <div class="title">{{ activeTitleText }}</div>
                    <div class="paragraphs" v-html="activeParagraphText"></div>
                    <div class="buttonContainer">
                        <a class="actionButton noprint" href="https://www.mtabenefits.com/" target="_blank">{{ activeSignUpText }}</a>
                        <SendEmailModal type="PrintPage" v-if="showEmail"
                                        elementId="infoAccordion"
                                        sender="Jellyvision Custom <info@jellyvisioncustom.com>"
                                        subject="MTA Benefits Member Lounge Results"
                                        attachmentName="MTAB Information Results.pdf"
                                        :template="emailTemplate"
                                        :templateVariables=emailTemplateVariables
                                        :inlineImages="emailImages"
                                        :openModalText="activeOpenEmailModalText"
                                        :enterEmailText="activeEnterEmailText"
                                        :sendEmailText="activeSendEmailText"
                                        :generatingText="activeGeneratingText"
                                        :emailSentText="activeEmailSentText"
                                        :showEmailIcon="false"
                                        templateType="ResultsLogoOnly"
                                        ></SendEmailModal>
                        <a class="transparentButton noprint" href="https://www.mtabenefits.com/contact-us" target="_blank">{{ activeContactText }}</a>
                    </div>
                </div>
                <div class="imageContainer">
                    <img src="/images/MTAB/Resource-page-illustration.png"/>
                </div>
            </div>
            <div id="infoAccordion">
                <Accordion v-if="config.accordionCode && language" 
                            :language="language.name"
                            :accordionCode="config.accordionCode" 
                            :displayTitle="false" 
                            :hasDisplayLogic="true"
                            :logicParameters="$parent.piniaSurvey.getAttributes"
                            :replacements="replacements" ></Accordion>
            </div>
        </div>
    </div>
</template>

<script>
import { data } from '@/shared';
import Accordion from '@/components/shared/Accordion';
import SendEmailModal from "@/components/shared/SendEmailModal";
require('@/assets/css/shared/send_email/send_email_variables_standard.scss');
import Breadcrumb from '@/components/shared/Breadcrumb';

export default ({
    components: { Accordion, SendEmailModal, Breadcrumb },
    name: 'InformationPage',
    data() {
        return {
            emailTemplateVariables: {
                logo_src: 'cid:logo.png',
                return_url: window.location.href
            },
            emailImages: [],
            showEmail: true,
            activeTitleText: '',
            activeParagraphText: '',
            activeSignUpText: '',
            activeContactText: '',
            activeOpenEmailModalText: '',
            activeEnterEmailText: '',
            activeSendEmailText: '',
            activeGeneratingText: '',
            activeEmailSentText: '',
            activePrintText: '',
            breadcrumbPages: [
                {
                    number: 1,
                    titleJson: [
                        {
                            language: "English",
                            text: "Home"
                        },
                        {
                            language: "Spanish",
                            text: "Hogar"
                        },
                        {
                            language: "Portuguese",
                            text: "Lar"
                        }
                    ]
                },
                {
                    number: 2,
                    titleJson: [
                        {
                            language: "English",
                            text: "Questions"
                        },
                        {
                            language: "Spanish",
                            text: "Preguntas"
                        },
                        {
                            language: "Portuguese",
                            text: "Questões"
                        }
                    ]
                },
                {
                    number: 3,
                    titleJson: [
                        {
                            language: "English",
                            text: "Suggestions"
                        },
                        {
                            language: "Spanish",
                            text: "Sugerencias"
                        },
                        {
                            language: "Portuguese",
                            text: "Sugestões"
                        }
                    ]     
                },
            ],
            startOverText: {
                english: "Start Over",
                spanish: "Empezar de nuevo",
                portuguese: "Recomeçar"

            },
            printText: {
                english: "Print",
                spanish: "Imprimir",
                portuguese: "Imprimir"
            },
            titleText: {
                english: "Your Unique Recommendations",
                spanish: "Sus Recomendaciones Unicas",
                portuguese: "Suas Recomendações Exclusivas"
            },
            paragraphText: {
                english: "Based on your responses, we think you'll love the benefits below. They can save you serious money, offer important financial protection, and make your life easier. Keep an eye out for the <i class=\"fa-solid fa-star\" style=\"color: #199FFE;\"></i> icon; it means the benefit is available to your family members, whether or not you participate in the program yourself.<br/><br/>If you want these benefits to start working for you, just sign up or log in to your account at MTA Benefits. You can find all the information you need there, including how to apply for these awesome options. Plus, you'll get access to current local ticket discounts, coupons, giveaways, and webinars. And don't worry, if you have any questions, we're here to help!",
                spanish: "Según sus respuestas, creemos que le encantarán los siguientes beneficios. Pueden ahorrarle mucho dinero, ofrecerle una protección financiera importante y hacerle la vida más fácil. Esté atento al icono <i class=\"fa-solid fa-star\" style=\"color: #199FFE;\"></i>; significa que el beneficio está disponible para los miembros de su familia, ya sea que participe o no en el programa.<br/><br/>Si desea que estos beneficios comiencen a trabajar para usted, simplemente regístrese o inicie sesión en su cuenta en MTA Benefits. Puede encontrar toda la información que necesita allí, incluido cómo solicitar estas increíbles opciones. Además, obtendrá acceso a descuentos, cupones, obsequios y seminarios web actuales en boletos locales. Y no te preocupes, si tienes alguna duda, ¡estamos aquí para ayudarte!",
                portuguese: "Com base em suas respostas, achamos que você vai adorar os benefícios abaixo. Eles podem economizar muito dinheiro, oferecer proteção financeira importante e facilitar sua vida. Fique atento ao ícone <i class=\"fa-solid fa-star\" style=\"color: #199FFE;\"></i>; significa que o benefício está disponível para seus familiares, independentemente de você participar ou não do programa.<br/><br/>Se quiser que esses benefícios comecem a funcionar para você, basta se cadastrar ou fazer login em sua conta no MTA Benefícios. Você pode encontrar todas as informações de que precisa lá, incluindo como se inscrever para essas opções incríveis. Além disso, você terá acesso a descontos, cupons, brindes e webinars locais atuais. E não se preocupe, se tiver alguma dúvida, estamos aqui para ajudar!"
            },
            signUpText: {
                english: "TAKE ME TO MTAB",
                spanish: "LLEVAME A MTAB",
                portuguese: "LEVE-ME AO MTAB"
            },
            contactText: {
                english: "CONTACT MTAB",
                spanish: "CONTACTA MTAB",
                portuguese: "CONTATE MTAB"
            },
            openEmailModalText: {
                english: "SEND ME THIS LIST",
                spanish: "ENVÍAME ESTA LISTA",
                portuguese: "ME ENVIE ESSA LISTA"
            },
            enterEmailText: {
                english: "Enter your Email Address",
                spanish: "Ingrese su dirección de correo electrónico",
                portuguese: "Insira o seu endereço de email"
            },
            sendEmailText: {
                english: "SEND EMAIL",
                spanish: 'ENVIAR EMAIL',
                portuguese: 'ENVIAR EMAIL'
            },
            generatingText: {
                english: "GENERATING PDF",
                spanish: 'GENERANDO PDF',
                portuguese: 'GERANDO PDF'
            },
            emailSentText: {
                english: 'EMAIL SENT!',
                spanish: '¡EMAIL ENVIADO!',
                portuguese: 'E-MAIL ENVIADO!'
            }
        }
    },
    props: {
        config: Object,
        onClickStartOver : {
            type: Function
        },
        corporateLogoLocation: {
            type: String,
            default: null
        },
        emailTemplate: {
            type: String 
        },
        replacements: {
            type: Object
        },
        language: Object,
        onBreadcrumbClick: Function
    },
    methods: {
        print: function() {
            window.print();
        },
        loadLanguage: function(language) {
            if(language != null)
            {
                this.activeParagraphText = this.paragraphText[language.name.toLowerCase()];
                this.activeTitleText = this.titleText[language.name.toLowerCase()];
                this.activeSignUpText = this.signUpText[language.name.toLowerCase()];
                this.activeContactText = this.contactText[language.name.toLowerCase()];
                this.activeOpenEmailModalText = this.openEmailModalText[language.name.toLowerCase()];
                this.activeEnterEmailText = this.enterEmailText[language.name.toLowerCase()];
                this.activeSendEmailText = this.sendEmailText[language.name.toLowerCase()];
                this.activeGeneratingText = this.generatingText[language.name.toLowerCase()];
                this.activeEmailSentText = this.emailSentText[language.name.toLowerCase()];
                this.breadcrumbPages[0].title = this.breadcrumbPages[0][language.name.toLowerCase()];
                this.breadcrumbPages[1].title = this.breadcrumbPages[1][language.name.toLowerCase()];
                this.breadcrumbPages[2].title = this.breadcrumbPages[2][language.name.toLowerCase()];
                this.activePrintText = this.printText[language.name.toLowerCase()];
            }
        },
        breadcrumbPageClick: function(breadcrumbPage) {
            if(this.onBreadcrumbClick)
                this.onBreadcrumbClick(breadcrumbPage);        
        }
    },
    async mounted() {
        this.loadLanguage(this.language);
        this.$parent.piniaSurvey.setAttributes(null);
        const fileStorageUrl = await data.getEnvironmentVariable('fileStorageUrl');
        this.emailImages.push({
            url: fileStorageUrl + "/MTAB/mtab-logo.png",
            name: 'logo.png'
        });
    },
    watch: {
    language: function(newValue) {
        this.loadLanguage(newValue);
    }
  }
})
</script>
<style lang="scss" scoped src="@/assets/css/shared/send_email/send_email_modal.scss"></style>