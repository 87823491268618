<template>
<div class="home-page base-font" v-if="page >= 0">
    <div class="header noprint" :class="headerClass">
        <div>
            <img v-if="loaConfiguration.webPageLogoPath" :src="loaConfiguration.webPageLogoPath" />
        </div>
        <div>
            Retiree Health Plan Premium Estimator
        </div>
        <div :style="showYear">
            2025
        </div>
    </div>
    <div class="content" :class="contentClass">
        <div :class="surveyContainerClass" v-if="page==0">        
            <Survey3 code="UOFC" :onEndOfSurvey="local_onEndOfSurvey" :onPageDisplay="onSurveyPageDisplay" :totalPages="4" :startProgressBarPagingAt="3"
                goBackButtonText="Back" :gotoPage="activeSurveyPageIndex" :onValidateAnswer="ValidateAnswer">
            </Survey3>
        </div>
        <div v-if="page==1 && activeInfoPageConfig" class="accordion-container">
            <div class="back-button noprint" @click="GoBack">
                <span v-if="showBackOnInfoPage"><i class="fa-solid fa-angle-left"></i> Back</span>
            </div>
            <LoaInformationPage :config="activeInfoPageConfig" :replacements="replacements"></LoaInformationPage>
            <div class="start-over-button noprint" @click="StartOver">
                <i class="fa-solid fa-angle-left"></i> Start Over
            </div>
        </div>
    </div>
    <div class="footer-container noprint" >
        <div class="logo-container">
            <img v-if="loaConfiguration.webPageLogoPath" :src="loaConfiguration.webPageLogoPath" />
        </div>
        <div>   
            <b>Need help?</b> Contact the UC Retirement<br />
            Administration Service Center (RASC).
        </div>
        <div>
            Phone: 1-800-888-8267<br />
            Hours: Monday–Friday, 7 a.m. to 4:30 p.m. (PT)
        </div>
    </div>
</div>
</template>
<script>

require('@/assets/css/client/UofC/variables.scss');
import Survey3 from "@/components/shared/Survey/Survey3";
import LoaInformationPage from '@/components/shared/LOA/LoaInformationPage';
import { useLoaStore } from "@/stores/loa";
import { data } from '@/shared';
export default {
  components: { Survey3, LoaInformationPage},
  name: "LeaveOfAbsence",
  computed: {
      year() { 
        return new Date().getFullYear();
      },
      showBackOnInfoPage() {
        return this.piniaSurvey.getAttributes["CALC_RETIREMENT_PERC"]
            && this.piniaSurvey.getAttributes["CALC_RETIREMENT_PERC"] !== 'N/A';
      },
      headerClass() {
        if(this.page > 0) 
            return "tall-header-background info-page-header";
        else if(this.activeSurveyPageIndex <= 1)
            return "tall-header-background big-header";
        else 
            return "short-header";
      },
      contentClass() {
        if(this.page > 0) 
            return "info-page-background";
        else if(this.activeSurveyPageIndex <= 1)
            return "survey-page-background";
        else 
            return "";
      },

      surveyContainerClass() {
        return this.activeSurveyPageIndex <= 1 ? "fixed-survey-container" : "standard-survey-container";
      },
      showYear() {
        if( this.activeInfoPageConfig != null)
            return { display: "none" };
        else 
            return {}; 
      },
    emailTemplate() {
        if(this.loaConfiguration.emailTemplate)
            return this.loaConfiguration.emailTemplate;
        else 
            return "loaresultsemail";
    }
  },
  data() {
    return {
      page: -1,
      activeSurveyPageIndex: null,
      showModal: false,
      surveyCode: null,
      activeInfoPageConfig: null,
      replacements: {},
      loaConfiguration: {},
      plans: [
        {   
            planCodes: ['CORE_PPO', 'SPLIT_CORE_PPO'],
            costs: [
                {level: 'U', premium: 430.37, max_contrib: 309.76},
                {level: 'UC', premium: 774.67, max_contrib: 557.58},
                {level: 'UA', premium: 903.78, max_contrib: 602.52},
                {level: 'UAC', premium: 1248.08, max_contrib: 850.34},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MA', premium: 997.11, max_contrib: 724.36},
                {level: 'MC', premium: 868, max_contrib: 679.42},
                {level: 'MAC', premium: 1341.41, max_contrib: 972.18},
                {level: 'MMC', premium: 1391.70, max_contrib: 1111.02}
            ],                
        },
        {   
            planCodes:['HSP_PPO'],
            costs:[
                {level: 'U', premium: 959.55, max_contrib: 568.92},
                {level: 'UC', premium: 1727.19, max_contrib: 1024.06},
                {level: 'UA', premium: 2015.06, max_contrib: 1135.94},
                {level: 'UAC', premium: 2782.70, max_contrib: 1591.08},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MA', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium: 'N/A', max_contrib: 0},
                {level: 'MAC', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium: 'N/A', max_contrib: 0}
            ],                
        },
        {   
            planCodes: ['MEDICARE_PPO'],
            costs:[
                {level: 'U', premium: 'N/A', max_contrib: 0},
                {level: 'UC', premium: 'N/A', max_contrib: 0},
                {level: 'UA', premium: 'N/A', max_contrib: 0},
                {level: 'UAC', premium: 'N/A', max_contrib: 0},
                {level: 'M', premium: 523.70, max_contrib: 431.60},
                {level: 'MM', premium: 1047.40, max_contrib: 863.20},
                {level: 'MMM', premium: 1571.10, max_contrib: 1294.80},
                {level: 'MA', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium: 'N/A', max_contrib: 0},
                {level: 'MAC', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium: 'N/A', max_contrib: 0}
            ],                
        },
        {   
            planCodes: ['MEDICARE_PPO_NO_RX'],
            costs:[
                {level: 'U', premium: 'N/A', max_contrib: 0},
                {level: 'UC', premium: 'N/A', max_contrib: 0},
                {level: 'UA', premium: 'N/A', max_contrib: 0},
                {level: 'UAC', premium: 'N/A', max_contrib: 0},
                {level: 'M', premium: 143.33, max_contrib: 431.60},
                {level: 'MM', premium: 286.66, max_contrib: 863.20},
                {level: 'MMM', premium: 429.99, max_contrib: 1294.80},
                {level: 'MA', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium: 'N/A', max_contrib: 0},
                {level: 'MAC', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium: 'N/A', max_contrib: 0}
            ],                
        },
        {   
            planCodes: ['BLUE_GOLD_HMO', 'SPLIT_BLUE_GOLD_HMO'],
            costs: [
                {level: 'U', premium: 1028.96, max_contrib: 726.43},
                {level: 'UC', premium: 1852.13, max_contrib: 1307.57},
                {level: 'UA', premium: 2160.82, max_contrib: 1466.71},
                {level: 'UAC', premium: 2983.99, max_contrib: 2047.85},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MA', premium: 1605.93, max_contrib: 1171.88},
                {level: 'MC', premium: 1297.24, max_contrib: 1012.74},
                {level: 'MAC', premium: 2429.10, max_contrib: 1753.02},
                {level: 'MMC', premium: 1771.31, max_contrib: 1444.34}
            ],                
        },
        {   
            planCodes: ['MEDICARE_HIGH_OPTION'],
            costs:[
                {level: 'U', premium: 'N/A', max_contrib: 0},
                {level: 'UC', premium: 'N/A', max_contrib: 0},
                {level: 'UA', premium: 'N/A', max_contrib: 0},
                {level: 'UAC', premium: 'N/A', max_contrib: 0},
                {level: 'M', premium: 731.75, max_contrib: 431.60},
                {level: 'MM', premium: 1463.50, max_contrib: 863.20},
                {level: 'MMM', premium:  2195.25, max_contrib: 1294.80},
                {level: 'MA', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium: 'N/A', max_contrib: 0},
                {level: 'MAC', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium: 'N/A', max_contrib: 0}
            ],                
        },        
        {   
            planCodes: ['KAISER_HMO', 'SPLIT_KAISER', 'MEDICARE_KAISER'],
            costs: [
                {level: 'U', premium: 819.46 , max_contrib: 602.96},
                {level: 'UC', premium: 1475.03, max_contrib: 1085.33},
                {level: 'UA', premium: 1720.87, max_contrib: 1207.42},
                {level: 'UAC', premium: 2376.44, max_contrib: 1689.79 },
                {level: 'M', premium: 232.03, max_contrib: 431.60},
                {level: 'MM', premium: 464.06, max_contrib: 863.20},
                {level: 'MMM', premium: 696.09, max_contrib: 1294.80},
                {level: 'MA', premium: 1133.44, max_contrib: 1036.06},
                {level: 'MC', premium: 887.60, max_contrib: 913.97},
                {level: 'MAC', premium: 1789.01, max_contrib: 1518.43},
                {level: 'MMC', premium: 1119.63 , max_contrib: 1345.57}
            ],                
        },
        {   
            planCodes: ['CARE_PPO', 'SPLIT_CARE_PPO'],
            costs: [
                {level: 'U', premium: 1540.99, max_contrib: 1060.45},
                {level: 'UC', premium: 2773.78, max_contrib: 1908.81},
                {level: 'UA', premium: 3236.08, max_contrib: 2168.15},
                {level: 'UAC', premium: 4468.87, max_contrib: 3016.51},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MA', premium: 2218.79, max_contrib: 1539.30},
                {level: 'MC', premium: 1756.49, max_contrib: 1279.96},
                {level: 'MAC', premium: 3451.58, max_contrib: 2387.66},
                {level: 'MMC', premium: 2280.19 , max_contrib: 1711.56}
            ],                
        },
        {   
            planCodes: ['MEDICARE_CHOICE'],
            costs: [
                {level: 'U', premium: 'N/A', max_contrib: 0},
                {level: 'UC', premium: 'N/A', max_contrib: 0},
                {level: 'UA', premium: 'N/A', max_contrib: 0},
                {level: 'UAC', premium: 'N/A', max_contrib: 0},
                {level: 'M', premium: 474.07, max_contrib: 431.60},
                {level: 'MM', premium: 948.14, max_contrib: 863.20},
                {level: 'MMM', premium:  1422.21, max_contrib: 1294.80},
                {level: 'MA', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium: 'N/A', max_contrib: 0},
                {level: 'MAC', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium: 'N/A', max_contrib: 0}
            ],                
        },
        {   
            planCodes: ['DENTAL_PPO'],
            costs: [
                {level: 'U', premium: 44.24, max_contrib: 44.24},
                {level: 'UC', premium: 79.63, max_contrib: 79.63},
                {level: 'UA', premium: 92.90, max_contrib: 92.90},
                {level: 'UAC', premium: 128.29, max_contrib: 128.29},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium:  'N/A', max_contrib: 0},
                {level: 'MA', premium:  'N/A', max_contrib: 0},
                {level: 'MAC', premium:  'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium:  'N/A', max_contrib: 0}
            ],                
        },
        {   
            planCodes: ['DENTAL_HMO'],
            costs: [
                {level: 'U', premium: 18.09, max_contrib: 18.09},
                {level: 'UC', premium: 32.56, max_contrib: 32.56},
                {level: 'UA', premium: 37.99, max_contrib: 37.99},
                {level: 'UAC', premium: 52.46, max_contrib: 52.46},
                {level: 'M', premium: 'N/A', max_contrib: 0},
                {level: 'MM', premium: 'N/A', max_contrib: 0},
                {level: 'MC', premium:  'N/A', max_contrib: 0},
                {level: 'MA', premium:  'N/A', max_contrib: 0},
                {level: 'MAC', premium:  'N/A', max_contrib: 0},
                {level: 'MMM', premium: 'N/A', max_contrib: 0},
                {level: 'MMC', premium:  'N/A', max_contrib: 0}
            ],                
        },
      ],
      part_b_reimbursements: [
        { level: 'M', amount: 174.70},
        { level: 'MM', amount: 349.40},
        { level: 'MMM', amount: 524.10},
        { level: 'MA', amount: 0},
        { level: 'MC', amount: 26.37},
        { level: 'MAC', amount: 0},
        { level: 'MMC', amount: 225.94}
      ]
    };
  },  
  props: {
    onQuestionAnswered: {
        type: Function
    },
    clientCode: {
        type: String,
        default: ''
    },
  } ,  
  setup() {
    const piniaSurvey = useLoaStore();
    return {
        piniaSurvey
    };
  },
  async mounted() {
    document.documentElement.style.fontFamily = "Kievit";
    document.documentElement.style.fontWeight = 100;
    
    data.getClientLogoAssetID()
        .then((clientLogoAssetID) => {
            if(clientLogoAssetID > 0) {
                data.getMediaProperty(clientLogoAssetID) 
                    .then((clientLogoAsset) => {
                        if(clientLogoAsset)
                            this.loaConfiguration.webPageLogoPath = clientLogoAsset.configuration.src;
                    });
            }
        });


    this.piniaSurvey.setAttributes(null);
    this.loaConfiguration.webPageLogoPath = "images/UofC/logo.png";

    data.getPageProperties()
        .then((result) => {
            if(result.loaProperties && result.loaProperties.managerAccordionConfiguration && 
                result.loaProperties.managerAccordionConfiguration.accordionCode != '') {
                this.loaConfiguration.managerPathConfig = result.loaProperties.managerAccordionConfiguration;
            }

            if(result.emailTemplateMediaPropertyId > 0) {
                data.getMediaProperty(result.emailTemplateMediaPropertyId)
                    .then((emailResult) => {
                        this.loaConfiguration.emailTemplate = emailResult.configuration.templateName + '';
                        if(emailResult.configuration.openModalText)
                            this.emailOpenModalText = emailResult.configuration.openModalText;

                        if(emailResult.configuration.logoImageMediaPropertyId > 0) {
                            data.getMediaProperty(emailResult.configuration.logoImageMediaPropertyId)
                                .then((logoResult) => {
                                    this.loaConfiguration.emailCorporateLogoPath = logoResult.configuration.src;
                                });
                        }
                    });
            }
        });

    await this.GetClientConstants();

    data.postActivityLog({
        clientCode: this.clientCode,
        logActivityTypeCode: 'LANDING_PAGE'
    });

    document.addEventListener('click', function(event) {
        const target = event.target;
        
        if (target.tagName === 'A' && target.href) {
          var logObject = {
              url: target.href
          };

          data.postActivityLog({          
            clientCode: this.clientCode,
            logActivityTypeCode: 'CLICK_LINK',
            data: JSON.stringify(logObject),
          });
        }
    });

    this.page = 0;
  },
  methods: {
    onSurveyPageDisplay: function(survey, activePageIndex) {
        this.activeSurveyPageIndex = activePageIndex;
    } ,
    local_onEndOfSurvey: async function(action) {   
        this.getRelacementStrings();

        var attributes = this.piniaSurvey.getAttributes;
        if((attributes["CALC_RETIREMENT_PERC"] || attributes["CALC_RETIREMENT_PERC"] === 0) && 
                attributes["CALC_RETIREMENT_PERC"] !== 'N/A') {
            this.replacements["%CALC_RETIREMENT_PERC_FORMATTED%"] = Number(attributes["CALC_RETIREMENT_PERC"]) + '%';

            //search array of plan info for value in the array of plan codes.        
            var plan = this.plans.find(search => search.planCodes.find(searchPlan => searchPlan == attributes["HEALTH_PLAN"]));
            if(plan) {
                var percent = Number(attributes["CALC_RETIREMENT_PERC"] / 100);
                var uofc_paid = 0;

                plan.costs.forEach(planInfo => {
                    if(planInfo.premium != 'N/A') {
                        this.replacements["%UOFC_" + planInfo.level + "_PREMIUM%"] = this.formatCurrency(planInfo.premium);
                        this.replacements["%UOFC_" + planInfo.level + "_MAX_CONTRIB%"] = this.formatCurrency(planInfo.max_contrib);

                        if(attributes["CALC_RETIREMENT_PERC"] === 'N/A') {
                            this.replacements["%UOFC_" + planInfo.level + "_UC_PAYS%"] = "";
                            this.replacements["%UOFC_" + planInfo.level + "_MEMBER_PAYS%"] = "N/A";
                            this.replacements["%UOFC_" + planInfo.level + "_PART_B%"] = "";
                            return;
                        }

                        uofc_paid = Math.round((planInfo.max_contrib * percent) * 100) / 100;
                        if(uofc_paid > planInfo.premium) uofc_paid = planInfo.premium;
                    

                        this.replacements["%UOFC_" + planInfo.level + "_UC_PAYS%"] = this.formatCurrency(uofc_paid);
                        if(planInfo.premium > uofc_paid) {
                            this.replacements["%UOFC_" + planInfo.level + "_MEMBER_PAYS%"] = this.formatCurrency(planInfo.premium - uofc_paid);
                            this.replacements["%UOFC_" + planInfo.level + "_PART_B%"] = "";
                        }
                        else 
                        {
                            this.replacements["%UOFC_" + planInfo.level + "_MEMBER_PAYS%"] = "";

                            var partB_lookup = this.part_b_reimbursements.find(search => search.level === planInfo.level);
                            var partB = 0;
                            if(partB_lookup) {
                                partB = (planInfo.max_contrib * percent) - planInfo.premium;
                                if(partB < 0) 
                                    partB = 0;
                                else 
                                    if(partB_lookup.amount < partB) partB = partB_lookup.amount;
                            }
                            this.replacements["%UOFC_" + planInfo.level + "_PART_B%"] = partB > 0 ? this.formatCurrency(partB) : "";
                    
                        }

                    }
                    else {
                        this.replacements["%UOFC_" + planInfo.level + "_PREMIUM%"] = 'N/A';
                        this.replacements["%UOFC_" + planInfo.level + "_UC_PAYS%"] = "";
                        this.replacements["%UOFC_" + planInfo.level + "_MAX_CONTRIB%"] = "";
                        this.replacements["%UOFC_" + planInfo.level + "_MEMBER_PAYS%"] = "";
                        this.replacements["%UOFC_" + planInfo.level + "_PART_B%"] = "";
                    } 
                });
            }
        }

        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode:  'DISPLAY_ACCORDION',
            data: JSON.stringify(attributes)
        });

        if(action.subType === 'AccordionInformationPage')
           this.activeInfoPageConfig = action.configuration;

        this.page++;
    },
    formatCurrency(amount, currency = 'USD', locale = 'en-US') {
        return new Intl.NumberFormat(locale, {
            style: 'currency',
            currency: currency,
        }).format(amount);
    },
    getRelacementStrings() {
        this.replacements =  this.piniaSurvey.getReplacementStrings; 
        var client_replacements;    
    
        if(client_replacements == null) client_replacements = {};
        Object.entries(client_replacements).forEach(
            entry => {
                this.replacements[entry[0]] = entry[1];
            });
    },
    ShowContactModal: async function() {
        data.postActivityLog({
            clientCode: this.clientCode,
            logActivityTypeCode: 'CLICK_LEAVE_MGMT'
        });
        this.showModal = true;
    },
    closeModal: function() {
        this.showModal = false;
    },
    nextPage: function() {
        this.page++;
    },
    previousPage: function() {
        this.page--;
    },
    GoBack: function() {
        this.activeSurveyPageIndex = 5;
        this.page = 0;
    },
    StartOver: function() {
        this.activeSurveyPageIndex = 0;
        this.activeInfoPageConfig = null;
        this.piniaSurvey.setAttributes(null);
        this.page = 0;
    },
    GetClientConstants: async function() {
        const constants = await data.getClientConstants();

        //get attributes as replacement characters
        var replacementAttributes = await data.getReplacementStringAttributes();
        var replacementStrings = {};

        if(constants != null) {
            constants.forEach(constant => {
                switch(constant.key) {
                    case "LOA_SURVEY_CODE":
                        this.surveyCode = constant.value;
                        break;
                }            

                if(replacementAttributes.find(search => search.code === constant.key))
                    replacementStrings["%" + constant.key + "%"] = constant.value;

            });
        }

        replacementStrings['%FILE_STORAGE_URL%'] = await data.getEnvironmentVariable('fileStorageUrl');

        this.piniaSurvey.setReplacementStrings(replacementStrings);

        this.i++;
    },
    ValidateAnswer: function(code, answer) {
        if(code === "CALC_RETIREMENT_PERC") {
            if(answer) {
                var compare = Number(answer);
                var calcVal;

                for(var i = 0; i <= 10; i++) {
                    for(var j = 0; j <= 10; j++) {
                        calcVal = (j * 5) + (j * .5 * i);
                        
                        if( calcVal === compare)
                            return { success: true };
                    }
                }

                return {
                    success: false,
                    error_message: "Invalid contribution percentage"
                };
            }
        }

        return { success: true};
    }
  },
};
</script>
<style scoped>
    @import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');
</style>
<style scoped lang="scss" src="@/assets/css/client/UofC/style.scss"></style>
<style scoped> 
    .logo img {
        margin: -30px 0px;
    }

    .subheader_empty {
        height: 30px;
    }

    .subheader_with_text {
        height: 80px;   
    }
   
    .subheader_blue {
        background-color: #0981B0;   
    }
    .subheader_green {
        background-color: #3CBF00;
    }

    .subheader_teal {
        background-color: #048468;
    }

    .arrow_down_teal {
        border-top: 12px solid #048468;
    }


    .visible {
        display: unset;
    }

    .hidden {
        display:none;
    }

    .arrow-down {
    width: 0; 
    height: 0; 
    border-left: 10.5px solid transparent;
    border-right: 10.5px solid transparent;
    border-top: 12px solid transparent;
    margin-left: 200px;
    }
    @media only screen and (max-width: 962px) {
        .arrow-down {
            margin-left: 30px;
        }
    }
    .left-of-arrow {
        width: 140px;
        height: 12px;
        position: absolute;
        left: 0;
        background-color: rgb(47,47,82);
    }
    .right-of-arrow {
        width: 140px;
        height: 12px;
        position: absolute;
        right: 0;
        top: 0;
        background-color: rgb(47,47,82);
    }

    .arrow_down_purple {
    border-top: 12px solid #882f7d;
    }

    .arrow_down_blue {
    border-top: 12px solid #0981B0;
    }
    .arrow_down_green {
        border-top: 12px solid #3CBF00;
    }

    .flex_across {
        display: flex; 
        flex: 1 1 auto;
    }
</style>

