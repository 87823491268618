<template>
    <div class="breadcrumb">
        <div class="pastPage" v-for="(pg, index) in pastPages" :key="pg.number">
            <a @click="onPageClick(pg, index)" v-html="getTextForLanguage(pg.titleJson)"></a>
            <span v-if="index < (activePageNumber - 1)" class="separator" v-html="separator"></span>
        </div>
        <div class="currentPage" v-if="currentPage">
            <span v-html="getTextForLanguage(currentPage.titleJson)"></span>
        </div>
        <div class="futurePage" v-for="(pg, index) in futurePages" :key="pg.number">
            <span v-if="index === 0" class="separator" v-html="separator"></span>
            <a v-if="allowFuturePageClick" @click="onPageClick(pg, index)" v-html="getTextForLanguage(pg.titleJson)"></a>
            <span v-if="!allowFuturePageClick" v-html="getTextForLanguage(pg.titleJson)"></span>
            <span v-if="index < (futurePages.length - 1)" class="separator" v-html="separator"></span>
        </div>
    </div>
</template>

<script>
import { translation } from '@/shared/translation';

export default {
    name: 'Breadcrumb',
    props: {
        pages: {
            type: Array
        },
        activePageNumber: {
            type: Number,
            default: 0
        },
        separator: {
            type: String,
            default: '>'
        },
        showFuturePages: {
            type: Boolean,
            default: false
        },
        onBreadcrumbClick: Function,
        allowFuturePageClick: {
            type: Boolean,
            default: false
        },
        // { name: 'English', code: 'en' }
        language: Object,
    },
    computed: {
        pastPages: function() {
            return this.pages.filter(pg => pg.number < this.activePageNumber);
        },
        currentPage: function() {
            return this.pages.find(pg => pg.number === this.activePageNumber);
        },
        futurePages: function() {
            if(this.showFuturePages) {
                return this.pages.filter(pg => pg.number > this.activePageNumber);
            } else {
                return [];
            }
        }
    },
    methods: {
        onPageClick: function(pg, index) {
            if(this.onBreadcrumbClick)
                this.onBreadcrumbClick(pg, index);
        },
        getTextForLanguage: function(jsonField) {
            return translation.getTextForLanguage(jsonField, this.language.name);
        }    
    }
}
</script>
